<template>
  <v-card>
    <v-card-title>EMPRESAS</v-card-title>
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :page="current_page"
        :items="companies"
        :options.sync="options"
        :server-items-length="companies.length"
        :footer-props="{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': `${$t('Items por página')}`,
          'page-text': `{0}-{1} ${$t('de')} {2}`,
        }"
      >
        <!-- show-select -->


        <!-- no-data -->
        <template  v-slot:no-data>
          {{ getUserRole === 0? 'Un administrador no tiene empresas asociadas' : 'Aún no hay empresas asociadas' }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{item}">
          <v-btn
            class="channel-btn"
            text
            @click="$refs.company_info_modal.open(item.id)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!-- admin_company -->
        <template #[`item.credits`]="{item}">
          {{ item.credits? item.credits : '-' }}
        </template>


      </v-data-table>





    </v-card>
  </v-card>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {  ref } from '@vue/composition-api'

// sidebar

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,

      enabled_toggle_confirm_modal: false,

      allUsers: [],
      allChannelList: [],
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    // New configurations

    const tableColumns = [
      // { text: 'ID', value: 'company_id', align: 'center', sortable: false },
      { text: 'NOMBRE', value: 'company', align: 'center', sortable: false },
      { text: 'CREDITOS EN LA EMPRESA', value: 'credits', align: 'center', sortable: false },
      // { text: 'USER PROFILE ID', value: 'userprofile_id', align: 'center', sortable: false },
    ]

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })




    // New configurations


    return {
      tableColumns,
      options,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
