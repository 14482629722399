<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userData.name) }}</span> <!-- PEDIR USERNAME EN EP -->
          </v-avatar>

          <span class="mb-2">{{ userData.name }}</span>

          <v-chip
            label
            small
            
            :class="`v-chip-light-bg text-sm font-weight-semibold`"
          >
            {{ userData.role | translate  }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            {{$t('Detalles')}}
          </h2>

          <v-divider></v-divider>

          <v-list>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{$t('Nombre')}}:</span>
              <span class="text--secondary">{{ userData.name || 'Sin definir' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email || 'Sin definir' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{$t('Rol')}}:</span>
              <span class="text--secondary text-capitalize">{{ userData.role }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">{{$t('Teléfono')}}:</span>
              <span class="text--secondary">{{ userData.phone || 'Sin definir' }}</span>
            </v-list-item>

          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            {{$t('Editar')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="isChangePassOpen = !isChangePassOpen"
          >
            {{$t('Cambiar Contraseña')}}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <profile-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        @reload-data="$emit('reload-data')"
      ></profile-edit>
      
      <profile-change-pass
        :is-profile-change-pass-open.sync="isChangePassOpen"
      />
        <!-- :user-data="userData" -->
        <!-- @reload-data="$emit('reload-data')" -->
    </v-col>

  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import ProfileEdit from './ProfileEdit.vue'
import ProfileChangePass from './ProfileChangePass.vue'

export default {
  components: {
    ProfileEdit,
    ProfileChangePass
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    
  },
  setup() {
    const isBioDialogOpen = ref(false)
    const isChangePassOpen = ref(false)

    return {
      
      avatarText,
      kFormatter,
      isBioDialogOpen,
      isChangePassOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
