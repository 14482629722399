<template>
    <div>
    <v-card class="mb-4">
      <v-card-title>{{$t('Perfil')}}</v-card-title>
    </v-card>

    <v-row class="mb-4">
      
      <v-col
        cols="12"
        
        md="12"
        lg="12"
      > 
        <profile-bio-panel
          :user-data="userData"
          @reload-data="getUserData"
          />
      </v-col>
    </v-row>

    </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'

// import { ref } from '@vue/composition-api'
import ProfileBioPanel from '@/components/Profile/ProfileBioPanel.vue'
import UserDataProfileList from '@/components/Profile/UserDataProfileList';

export default {
  components: {
    ProfileBioPanel,
    UserDataProfileList,
  },
  data: () => {
    return {
      userData: {},
    };

  },
  mounted() {
    this.getUserData()
  },
  methods: {
    async getUserData() {
      try {
        const response = await this.$api.getUserDataForProfile()
        response.role = this.roleParser(response.role)
        // response.avatar = "1.png"
        this.userData = response
      } catch (e) {
        console.log(e);  
      }
    },
    roleParser(role) {
      const roles = {
        0: "Administrador",
				1: "Representante de país",
				2: "Coordinador regional",
				3: "Representante escolar",
      }
      return roles[role]
    }
  },

 
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

}

</script>

<style lang="scss" scoped>

</style>